<script setup lang="ts">
const props = defineProps({
  destinationsContent: {
    type: Object,
    required: true,
  },
  availableDestinations: {
    type: Object,
    default: {},
  },
})

const selectedDestinations = defineModel()

const mappingTopDestinations = [
  'Méditerranée', 'Europe du Nord', 'Caraïbes', 'Iles Grecques', 'Croisières fluviales', 'Tour du Monde', 'France', 'Transocéaniques', 'Destinations polaires',
]

const matchedDestinations = []
const otherDestinations = []

const showMore = ref(false)

let destinationsShowMoreClickCount = 0

props.destinationsContent.forEach((destination) => {
  if (mappingTopDestinations.includes(destination.name)) {
    matchedDestinations.push(destination)
  }
  else {
    otherDestinations.push(destination)
  }
})

const allDestinations = [...matchedDestinations, ...otherDestinations]

function toggleSelectedDestination(destination) {
  if (selectedDestinations.value && selectedDestinations.value.includes(destination.name)) {
    selectedDestinations.value = selectedDestinations.value.filter(item => item !== destination.name)
  }
  else {
    selectedDestinations.value.push(destination.name)
  }
}

const seeMore = () => {
  destinationsShowMoreClickCount++
  showMore.value = true
}
</script>

<template>
  <div class="top-destination-search-container">
    <span class="search-title">
      <span>
        {{ $t('search.navigation.dropdown.title') }}
      </span>
      <span
        v-if="selectedDestinations.length > 0"
        class="counter-destination"
      >
        <span class="destination-count">{{ selectedDestinations.length }} {{ selectedDestinations.length > 1 ? $t('search.navigation.dropdown.selected.plural') : $t('search.navigation.dropdown.selected.single') }}</span>
      </span>
    </span>
    <div class="destination-list">
      <template v-for="(destination, index) in allDestinations">
        <div
          v-if="index < 8 || showMore"
          :class="{ selected: selectedDestinations.includes(destination.name), disabled: false && Object.values(availableDestinations).length > 0 && !Object.values(availableDestinations).includes(destination.name) }"
          class="destination-item"
          @click="toggleSelectedDestination(destination)"
        >
          <NuxtImg
            loading="lazy"
            format="webp"
            width="70"
            densities="x1 x2"
            :src="destination.image.url"
            :alt="destination.name"
          />
          <div class="content-destination">
            <span class="destination-name">{{ destination.name }}</span>
            <!--            <span class="destination-count">{{ getNumberFromKey(availableDestinations, destination.name) }} {{ getNumberFromKey(availableDestinations, destination.name) > 1 ? $t('search.navigation.dropdown.count.plural') : $t('search.navigation.dropdown.count.single') }}</span> -->
          </div>
          <UiIconsCheck
            v-if="selectedDestinations.includes(destination.name)"
            class="check"
          />
        </div>
      </template>
    </div>
    <span
      v-if="destinationsShowMoreClickCount < 1 && matchedDestinations.length >= 8"
      class="see-more-cta"
      @click="seeMore"
    >
      <UiIconsPlus /> {{ $t('search.navigation.dropdown.link') }}
    </span>
  </div>
</template>

<style lang="scss">
.top-destination-search-container {
  display: flex;
  flex-direction: column;

  .search-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-style: italic;
    font-family: var(--font-secondary);
    font-weight: 500;
    padding-bottom: $space-md;

    .counter-destination {
      font-family: var(--font-primary);
      font-weight: 300;
      font-size: 1rem;
    }
  }

  .destination-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $space-md;

    @media (min-width: map-get($grid-breakpoints, md)) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      grid-template-columns: repeat(4, 1fr);
    }

    .destination-item {
      display: flex;
      border: 1px solid var(--color-navy-150);
      position: relative;
      cursor: pointer;

      &.selected {
        border-color: var(--color-navy-450);
      }

      &.disabled {
        opacity: .5;
        cursor: not-allowed;
        pointer-events: none;
      }

      img {
        width: $space-4xl;
        min-height: $space-4xl;
        object-fit: cover;
      }

      .content-destination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $space-xs $space-md;

        .destination-name {
          font-size: 1.1rem ;
        }

        .destination-count {
          font-size: 0.8rem;
          font-weight: 300;
        }
      }

      .check {
        position: absolute;
        right: 1rem;
        color: var(--color-green);
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }

  .see-more-cta {
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
    padding-top: $space-md;
    font-size: 1.2rem;
    gap: $space-xs;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }
}
</style>
